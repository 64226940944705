import React from 'react'
import { detect } from 'detect-browser'
import UnsupportedBrowser from 'components/UnsupportedBrowserPage'
import './index.css'
const App = React.lazy(() => import('./App'))

const Root = () => {
  const browser = detect()

  switch (browser && browser.name) {
    case 'chrome':
      return (
        <React.Suspense fallback={<div className="unsupported__container"></div>}>
          <App />
        </React.Suspense>
      )
    default:
      return <UnsupportedBrowser />
  }
}

export default Root
